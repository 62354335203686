$app-uat-colour: govuk-colour("orange");
$app-staging-colour: govuk-colour("turquoise");
$app-dev-colour: govuk-colour("pink");
$app-local-colour: govuk-colour("bright-purple");

.app-environment-uat {
  .govuk-header__container {
    border-bottom-color: $app-uat-colour;
  }

  .govuk-tag.govuk-phase-banner__content__tag {
    background-color: $app-uat-colour;
  }
}

.app-environment-staging {
  .govuk-header__container {
    border-bottom-color: $app-staging-colour;
  }

  .govuk-tag.govuk-phase-banner__content__tag {
    background-color: $app-staging-colour;
  }
}

.app-environment-dev {
  .govuk-header__container {
    border-bottom-color: $app-dev-colour;
  }

  .govuk-tag.govuk-phase-banner__content__tag {
    background-color: $app-dev-colour;
  }
}

.app-environment-local {
  .govuk-header__container {
    border-bottom-color: $app-local-colour;
  }

  .govuk-tag.govuk-phase-banner__content__tag {
    background-color: $app-local-colour;
  }
}
